import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  box-shadow: ",
        ";\n  gap: ",
        ";\n  padding: ",
        ";\n  width: 100%;\n  display: flex;\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  margin: 0;\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n  ",
        "\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  p {\n    :first-child {\n      margin-top: 0;\n    }\n\n    :last-child {\n      margin-bottom: 0;\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  flex-shrink: 0;\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    flex-direction: column;\n    gap: ",
        ";\n  "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n    flex-direction: column;\n    gap: ",
        ";\n\n    @media (min-width: ",
        ") {\n      flex-direction: row;\n      align-items: center;\n      justify-content: space-between;\n      gap: ",
        ";\n    }\n  "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    gap: ",
        ";\n  "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n    gap: ",
        ";\n  "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n    gap: ",
        ";\n  "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n    @media (min-width: ",
        ") {\n      align-items: flex-start;\n      ",
        "\n      font-weight: 700;\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n    font-weight: 700;\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n    @media (min-width: ",
        ") {\n      ",
        "\n      color: ",
        ";\n      margin-top: ",
        ";\n      margin-left: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n    margin-left: ",
        ";\n    margin-top: ",
        ";\n  "
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n  "
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n  "
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, pxToCssFont, pxToRem, colors, getTypography, shadows } from "@vfit/shared/themes";
export var Container = styled.article.withConfig({
    componentId: "sc-686b0c46-0"
})(_templateObject(), colors.$ffffff, pxToRem(6), shadows.card_overlay, pxToRem(16), pxToRem(20, 16), function(param) {
    var variant = param.variant;
    return variant ? containerVariants[variant] : "";
});
export var Header = styled.header.withConfig({
    componentId: "sc-686b0c46-1"
})(_templateObject1(), function(param) {
    var variant = param.variant;
    return variant ? headerVariants[variant] : "";
});
export var Title = styled.h2.withConfig({
    componentId: "sc-686b0c46-2"
})(_templateObject2(), getTypography("h5.regular"), function(param) {
    var variant = param.variant;
    return variant ? titleVariants[variant] : "";
});
export var Description = styled.section.withConfig({
    componentId: "sc-686b0c46-3"
})(_templateObject3(), pxToRem(16), getTypography("body1.regular"), function(param) {
    var variant = param.variant;
    return variant ? descriptionVariants[variant] : "";
});
export var ImgWrapper = styled.div.withConfig({
    componentId: "sc-686b0c46-4"
})(_templateObject4(), pxToRem(48), pxToRem(48));
export var ChildrenWrap = styled.div.withConfig({
    componentId: "sc-686b0c46-5"
})(_templateObject5(), function(param) {
    var variant = param.variant;
    return variant ? childrenVariants[variant] : "";
});
// Variants
var containerVariants = {
    default: css(_templateObject6(), pxToRem(16)),
    "full-screen": css(_templateObject7(), pxToRem(16), breakpoints.tablet, pxToRem(32))
};
var headerVariants = {
    default: css(_templateObject8(), pxToRem(8)),
    "full-screen": css(_templateObject9(), pxToRem(16)),
    compact: css(_templateObject10(), pxToRem(12))
};
var titleVariants = {
    default: css(_templateObject11(), breakpoints.desktop, pxToCssFont(28, 36)),
    "full-screen": css(_templateObject12(), breakpoints.tablet, pxToCssFont(20, 28), breakpoints.desktop, pxToCssFont(22, 32)),
    compact: css(_templateObject13(), pxToCssFont(20, 28), breakpoints.desktop, pxToCssFont(22, 32))
};
var descriptionVariants = {
    default: css(_templateObject14(), breakpoints.tablet, pxToCssFont(18, 24)),
    "full-screen": css(_templateObject15(), breakpoints.tablet, pxToCssFont(16, 22), colors === null || colors === void 0 ? void 0 : colors.$7e7e7e, pxToRem(-12), pxToRem(65), breakpoints.tablet, pxToCssFont(18, 24)),
    compact: css(_templateObject16(), pxToRem(60), pxToRem(-12))
};
var childrenVariants = {
    default: css(_templateObject17()),
    "full-screen": css(_templateObject18(), breakpoints.tablet, pxToRem(165), breakpoints.desktop, pxToRem(173))
};
